:root {
  --background-color: #171923;
}
.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c {
  background: var(--background-color);
}

button.gm-ui-hover-effect > span {
  display: none !important;
}

button.gm-ui-hover-effect::before {
  content: 'x';
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: large;
}
